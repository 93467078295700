<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('fertilizerReport.carrirngAgentWiseProgramReport') }}</h4>
        </template>
      <template v-slot:body>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset">
            <b-row>
                <b-col xs="12" sm="12" md="6">
                  <ValidationProvider name="Program No" vid="program" rules="required">
                    <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="program"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('movement.program_no')}}  <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                          id="program_no"
                          v-model="search.program_name"
                          :state="errors[0] ? false : (valid ? true : null)"
                          :placeholder="$t('movement.program_no')"
                      ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="6">
                <ValidationProvider name="Vessels Name" vid="ship_name">
                  <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="ship_name"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('movement.vesselsName')}}
                        </template>
                        <b-form-input
                            id="ship_name"
                            v-model="search.ship_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :placeholder="$t('movement.vesselsName')"
                             v-if="currentLocale === 'en'"
                            readonly
                        ></b-form-input>
                        <b-form-input
                          id="ship_name"
                          v-model="search.ship_name_bn"
                          :placeholder="$t('movement.vesselsName')"
                          v-else
                          readonly
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-row>
              <b-col xs="12" sm="12" md="6">
                <ValidationProvider name="Date" vid="date">
                  <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="date"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.date')}}
                        </template>
                        <b-form-input
                            id="date"
                            v-model="search.date"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :placeholder="$t('globalTrans.date')"
                            readonly
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6">
                <ValidationProvider name="Fertilizer Name" vid="fertilizer_name">
                  <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="fertilizer_name"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('movement.fertilizer_name')}}
                        </template>
                        <b-form-input
                            id="date"
                            v-model="search.fertilizer_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :placeholder="$t('fertilizerConfig.port_name')"
                             v-if="i18 === 'en'"
                            readonly
                        ></b-form-input>
                        <b-form-input
                            id="date"
                            v-model="search.fertilizer_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :placeholder="$t('fertilizerConfig.port_name')"
                             v-else
                            readonly
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-row>
              <b-col xs="12" sm="12" md="6">
                <ValidationProvider name="port" vid="port">
                  <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="port"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('movement.port')}}
                        </template>
                        <b-form-input
                            id="date"
                            v-model="search.port_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :placeholder="$t('movement.port')"
                             v-if="currentLocale === 'en'"
                            readonly
                        ></b-form-input>
                        <b-form-input
                            id="date"
                            v-model="search.port_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :placeholder="$t('movement.port')"
                             v-else
                            readonly
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6">
                <ValidationProvider name="Fertilizer Total Amount" vid="FertilizerTotalAmount">
                  <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="FertilizerTotalAmount"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('fertilizerReport.FertilizerTotalAmount')}}
                        </template>
                        <b-form-input
                            id="date"
                            v-model="search.fertilizer_total_amount"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :placeholder="$t('fertilizerReport.FertilizerTotalAmount')"
                            readonly
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
             <b-row>
              <b-col xs="12" sm="12" md="6">
                <ValidationProvider name="Fertilizer Allocated Amount" vid="FertilizerAllocatedAmount">
                  <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="FertilizerAllocatedAmount"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('fertilizerReport.FertilizerAllocatedAmount')}}
                        </template>
                        <b-form-input
                            id="FertilizerAllocatedAmount"
                            v-model="search.fertilizer_allocated_amount"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :placeholder="$t('fertilizerReport.FertilizerAllocatedAmount')"
                            readonly
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6">
                  <b-button type="submit" variant="primary float-right">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
            </b-row>
           </b-form>
        </ValidationObserver>
      </template>
    </iq-card>
      <b-row v-show="showData">
        <b-col md="12">
          <iq-card>
            <template v-slot:body>
              <b-overlay :show="loadingState">
                  <b-row v-show="showData">
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('fertilizerReport.carrirngAgentWiseProgramReport') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button class="btn_add_new mr-2" @click="pdfExport">
                            <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                          </b-button>
                          <export-excel
                            class="btn btn_add_new"
                            :data="dataCustomize"
                            :fields= "json_fields"
                            :title="$t('fertilizerReport.TransportAgentWiseFertilizerAmountAgainstProgramNo')"
                            :name="$t('fertilizerReport.carrirngAgentWiseProgramReport') + '.xls'">
                            <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                          </export-excel>
                        </template>
                        <template v-slot:body>
                          <b-overlay>
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;">
                                  <b-row>
                                    <b-col>
                                      <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="fertilizer/config/report-heading/detail" :org-id="3">
                                        <u>{{ $t('fertilizerReport.carrirngAgentWiseProgramReport') }}</u>
                                      </list-report-head>
                                      <div class="text-center">
                                        <table style="width:100%;color:black;margin:20px;">
                                          <tr>
                                            <td align="left" style="width:20%">{{ $t('movement.program_no') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:25%">{{ search.program_name }}</td>
                                            <td align="left" style="width:20%">{{ $t('globalTrans.date') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:25%">{{ search.date | dateFormat }}</td>
                                          </tr>
                                          <tr>
                                            <td align="left">{{ $t('movement.vesselsName') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left">{{ ($i18n.locale==='bn') ? search.ship_name_bn : search.ship_name }}</td>
                                            <td align="left">{{ $t('fertilizerConfig.port_name') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left">{{ ($i18n.locale==='bn') ? search.port_name_bn : search.port_name }}</td>
                                          </tr>
                                          <tr>
                                            <td align="left">{{ $t('fertilizerReport.FertilizerTotalAmount') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left">{{ $n(search.fertilizer_total_amount) }}</td>
                                            <td align="left">{{ $t('movement.fertilizer_name') }} </td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left">{{ ($i18n.locale==='bn') ? search.fertilizer_name_bn : search.fertilizer_name }}</td>
                                          </tr>
                                          <tr>
                                            <td align="left">{{ $t('fertilizerReport.FertilizerAllocatedAmount') }} </td>
                                            <td  align="center" style="width:5%">:</td>
                                            <td align="left">{{ $n(search.fertilizer_allocated_amount) }}</td>
                                            <td align="left"></td>
                                            <td align="center" style="width:5%"></td>
                                            <td align="left"></td>
                                          </tr>
                                        </table>
                                      </div>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <div class="col text-right">
                                      <span class="text-right text-dark font-weight-bold"> {{ $t('fertilizerReport.accountMayTons') }}</span>
                                    </div>
                                  </b-row>
                                  <b-table-simple class="tg" hover bordered small caption-top  v-if="datas.godowns.length>0">
                                    <b-thead>
                                      <b-tr>
                                        <b-td class="tg-0lax" rowspan="2" style="width:5%">{{$t('globalTrans.sl_no')}}</b-td>
                                        <b-td class="tg-0lax" rowspan="2" style="width:20%">{{$t('fertilizerConfig.godown_information.warehouse_name')}} </b-td>
                                        <b-td class="tg-0pky" :colspan="datas.agents.length">{{ $t('fertilizerReport.TransportAgentWiseFertilizerAmountAgainstProgramNo') }}</b-td>
                                        <b-td class="tg-0lax" rowspan="2" style="width:15%">{{ $t('fertilizerReport.GrandTotalMTon') }}</b-td>
                                      </b-tr>
                                      <b-tr>
                                        <b-td class="tg-0lax"  v-for="(agent, index) in datas.agents" :key="index">{{ ($i18n.locale==='bn') ? agent.company_agent_name_bn  :  agent.company_agent_name  }}</b-td>
                                      </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                      <b-tr  v-for="(godown, index1) in datas.godowns" :key="index1">
                                        <b-td class="tg-0lax">{{$n(index1+1)}}</b-td>
                                        <b-td class="tg-0lax">{{ ($i18n.locale==='bn') ? godown.name_bn  :  godown.name  }}</b-td>
                                        <b-td class="tg-0lax" v-for="(agent, index2) in godown.agents" :key="index2">{{$n(agent.amount)}}</b-td>
                                        <b-td class="tg-0lax">{{$n(godown.total)}}</b-td>
                                      </b-tr>
                                      <b-tr>
                                        <b-th class="tg-0lax text-right" colspan="2" align="right">{{ $t('fertilizerReport.GrandTotalMTon') }} </b-th>
                                        <b-th class="tg-0lax"  v-for="(agent, index) in datas.agents" :key="index">{{$n(dataCalculationAgent(agent.id))}}</b-th>
                                        <b-th class="tg-0lax">{{$n(dataCalculationTotal())}}</b-th>
                                      </b-tr>
                                    </b-tbody>
                                  </b-table-simple>
                                  <div class="panel-body text-center mt-3" v-else>
                                    <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
                                  </div>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
  </b-container>
</template>
<style type="text/css">
.tg  {border-collapse:collapse;border-spacing:0;}
.tg td{text-align: center !important; vertical-align: middle !important;}
.tg th{text-align: center !important; vertical-align: middle !important;}
</style>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, carrirngAgentWiseProgramReport, ProgramNoSerach, portwiseAllocation } from '../../api/routes'
import ListReportHead from '.././ListReportHead.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ExportPdfCarryng from './carring'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)
export default {
  name: 'UiDataTable',
  components: {
    ListReportHead,
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      showData: false,
      showHeading: false,
      deliveryScheduleList: [],
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      search: {
        program_name: '',
        fertilizer_allocated_amount: 0,
        fertilizer_total_amount: 0,
        date: ''
      },
      datas: {
        agents: [],
        godowns: []
      },
      rows: [],
      excelData: [],
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingList: reportHeadingList
    }
  },
  computed: {
    json_fields: function () {
      let excelColumn = {}
      if (this.$i18n.locale === 'bn') {
        excelColumn = {
          'ক্রমিক নং': 'serial',
          'গুদামের নাম': 'name_bn'
        }
        this.datas.agents.map((item, index) => {
          excelColumn['"' + item.company_agent_name_bn + '"'] = item.id
        })
        excelColumn['গ্র্যান্ড টোটাল(এমটন)'] = 'total'
      } else {
        excelColumn = {
          Serial: 'serial',
          'Warehouse Name': 'name'
        }
        this.datas.agents.map((item, index) => {
          excelColumn['"' + item.company_agent_name + '"'] = item.id
        })
        excelColumn.total = 'total'
      }
      return excelColumn
    },
    dataCustomize () {
      const listData = this.datas.godowns
      var serial = 0
      const listContractor = listData.map(item => {
        serial += 1
        return Object.assign({}, item, {
          serial: this.$n(serial)
        })
      })
      const Footer = {
        serial: this.$i18n.locale === 'bn' ? 'গ্র্যান্ড টোটাল (এমটন)' : 'Grand Total (M.Ton)'
      }
      let totalAmount = 0
      this.datas.agents.forEach((element, key) => {
        const amount = this.dataCalculationAgent(element.id)
        totalAmount += amount
        Footer[element.id] = this.$n(amount)
      })
      Footer.total = totalAmount
      listContractor.push(Footer)
      return listContractor
    },
    currentLocale: function () {
      return this.$i18n.locale
    },
    formTitle () {
       return this.$t('fertilizerReport.carrirngAgentWiseProgramReport')
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    fertilizerNameList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    portList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.portInfoSetupList.filter(item => item.status === 1)
    }
  },
  watch: {
    loadingState: function (newVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.program_name': function () {
      this.datas = {
        agents: [],
        godowns: []
      }
      this.getProgramInfo()
    }
  },
  created () {
  },
  mounted () {
    core.index()
  },
  methods: {
    dataCalculationTotal () {
      let amount = 0
      this.datas.godowns.forEach((element, key) => {
        amount += element.total
      })
      return amount
    },
    dataCalculationAgent (agentId) {
      let amount = 0
      this.datas.godowns.forEach((element, key) => {
        element.agents.forEach((element2, key2) => {
          if (element2.id === agentId) {
            amount += element2.amount
          }
        })
      })
      return amount
    },
    async getAllocation () {
        const search = {
            lc_no: this.search.lc_no,
            port_id: this.search.port_id,
            cnf_agent_id: this.search.cnf_agent_id
        }
        const result = await RestApi.postData(seedFertilizerServiceBaseUrl, portwiseAllocation, search)
        if (result.success) {
          this.search.fertilizer_allocated_amount = result.data
        }
    },
    async getProgramInfo () {
      const result = await RestApi.getData(seedFertilizerServiceBaseUrl, ProgramNoSerach, this.search)
      if (!result.success) {
        this.search.lc_no = null
        this.search.port_id = null
        this.search.cnf_agent_id = null
        this.search.date = null
        this.search.ship_name = null
        this.search.ship_name_bn = null
        this.search.fertilizer_total_amount = null
        this.search.fertilizer_allocated_amount = null
        this.search.fertilizer_name = null
        this.search.fertilizer_name_bn = null
        this.search.port_name = null
        this.search.port_name_bn = null
      } else {
        const fartilizerObj = this.fertilizerNameList.find(item => item.value === result.data.fertilizer_id)
        const portObj = this.portList.find(item => item.value === result.data.port_id)
        this.search.lc_no = result.data.lc_no
        this.search.port_id = result.data.port_id
        this.search.cnf_agent_id = result.data.cnf_agent_id
        this.search.date = result.data.allocation_date
        this.search.ship_name = result.data.ship_name
        this.search.ship_name_bn = result.data.ship_name_bn
        this.search.fertilizer_total_amount = result.data.quantity
        this.search.fertilizer_allocated_amount = result.data.total_amount
        // this.getAllocation()
        this.search.fertilizer_name = typeof fartilizerObj !== 'undefined' ? fartilizerObj.text_en : ''
        this.search.fertilizer_name_bn = typeof fartilizerObj !== 'undefined' ? fartilizerObj.text_bn : ''
        this.search.port_name = typeof portObj !== 'undefined' ? portObj.text_en : ''
        this.search.port_name_bn = typeof portObj !== 'undefined' ? portObj.text_bn : ''
      }
    },
    getCropNameList (Id = null) {
      return this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.crop_type_id === Id)
    },
    getVarietyList (cropID) {
      return this.$store.state.SeedsFertilizer.commonObj.VarietyList.filter(item => item.crop_name_id === cropID)
    },
    searchClick () {
      this.loadData()
    },
    rowClass (item, type) {
        var day = Math.ceil((Math.abs(new Date(item.delivery_date) - new Date())) / (1000 * 60 * 60 * 24))
        if (day <= 7 && type === 'row') {
            return 'table-danger'
        } else {
            return ''
        }
    },
    default () {
      return {
        id: this.rows.length
      }
    },
    searchData () {
      if (this.search.org_id > 0) {
        this.loadData()
        this.showData = true
        this.showHeading = true
      }
    },
    async register () {
      this.showData = true
      this.loadData()
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        await RestApi.getData(seedFertilizerServiceBaseUrl, carrirngAgentWiseProgramReport, this.search).then(response => {
      if (response.success) {
        if (response.data) {
          this.datas = response.data
          this.getPdfDataSetting()
        }
      } else {
        this.datas = []
      }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    pdfExport () {
      const rowData = this.getPdfData()
      const reportTypeTitle = ''
      const reportTitle = this.$i18n.locale === 'en' ? 'Carrying Agent Wise Program Report' : 'পরিবহন এজেন্ট ভিত্তিক সরবরাহের প্রতিবেদন'
      ExportPdfCarryng.exportPdfCarrying(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.datas, this, reportTypeTitle, rowData, this.search)
    },
    getPdfData () {
      const keys = []
      this.datas.agents.map((item, index) => {
        if (index === 0) {
          keys.push({ key: this.$i18n.locale === 'en' ? 'serial' : 'serial', style: 'td' })
          keys.push({ key: this.$i18n.locale === 'en' ? 'name' : 'name_bn', style: 'td' })
        }
        keys.push({
          key: item.id, style: 'td'
        })
        if (index === (this.datas.agents.length - 1)) {
          keys.push({ key: this.$i18n.locale === 'en' ? 'total' : 'total', style: 'th' })
        }
      })
      var serial = 0
      const listData = this.datas.godowns.map(item => {
        serial += 1
        const rowData = keys.map((keyItem, index) => {
          if (keyItem.key === 'serial') {
            return { text: this.$n(serial), style: 'td', alignment: 'center' }
          }
          if (keyItem.key === 'total') {
            return { text: this.$n(item.total), style: 'td', alignment: 'right' }
          }
          if (keyItem.key === 'name') {
            return { text: item[keyItem.key], style: 'td', alignment: 'center' }
          }
          return { text: item[keyItem.key], style: 'td', alignment: 'right' }
        })
        return rowData
      })
      const total = [
        this.$i18n.locale === 'en' ? { colSpan: 2, alignment: 'right', style: 'th', text: 'Grand Total', bold: true } : { colSpan: 2, alignment: 'right', style: 'th', text: 'সর্বমোট', bold: true },
        {}
      ]
      let totalAmount = 0
      this.datas.agents.map((element, key) => {
        const amount = this.dataCalculationAgent(element.id)
        totalAmount += amount
        total.push({ text: this.$n(amount), style: 'th', alignment: 'right', bold: true })
      })
      total.push({ text: this.$n(totalAmount), style: 'th', alignment: 'right', bold: true })
      listData.push(total)
      return listData
    },
    getPdfDataSetting () {
      const listData = this.datas.godowns.map((element, key) => {
        const lcData = {}
        element.agents.forEach((element2, key2) => {
          lcData[element2.id] = this.$n(element2.amount)
          return Object.assign({}, element2, {
            amount: this.dataCalculationAgent(element2.id)
          })
        })
        return Object.assign({}, element, lcData)
      })
      this.datas.godowns = listData
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
