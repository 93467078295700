import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfCarrying = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, typeName = '', newrowData, search) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
        }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          {
            text: vm.$t('fertilizerReport.krishi_bhaban'),
            style: 'krishi',
            alignment: 'center'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]

        if (reportHeadData.projectName) {
          pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
        }
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        if (typeName) {
          pdfContent.push({ text: typeName, style: 'report_type', alignment: 'center' })
        }
        const allRows = [
          [
            { text: vm.$t('movement.program_no'), style: 'th' },
            { text: ':', style: 'th', alignment: 'center' },
            { text: vm.$n(search.program_name), style: 'th' },
            { text: vm.$t('globalTrans.date'), style: 'th' },
            { text: ':', style: 'th', alignment: 'center' },
            { text: dateFormat(search.date), style: 'th' }
          ],
          [
            { text: vm.$t('movement.vesselsName'), style: 'th' },
            { text: ':', style: 'th', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.ship_name_bn : search.ship_name, style: 'th' },
            { text: vm.$t('fertilizerConfig.port_name'), style: 'th' },
            { text: ':', style: 'th', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.port_name_bn : search.port_name, style: 'th' }
          ],
          [
            { text: vm.$t('fertilizerReport.FertilizerTotalAmount'), style: 'th' },
            { text: ':', style: 'th', alignment: 'center' },
            { text: vm.$n(search.fertilizer_total_amount), style: 'th' },
            { text: vm.$t('movement.fertilizer_name'), style: 'th' },
            { text: ':', style: 'th', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.fertilizer_name_bn : search.fertilizer_name, style: 'th' }
          ],
          [
            { text: vm.$t('fertilizerReport.FertilizerAllocatedAmount'), style: 'th' },
            { text: ':', style: 'th', alignment: 'center' },
            { text: vm.$n(search.fertilizer_allocated_amount), style: 'th' },
            {},
            {},
            {}
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 2,
            widths: ['20%', '5%', '25%', '20%', '5%', '25%'],
            body: allRows
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        pdfContent.push({ text: vm.$t('fertilizerReport.accountMayTons'), style: 'fertilizer', alignment: 'right', bold: true })
        const rowData = newrowData
        const labels1 = []
        const labels = []
        const totalLength = data.agents.length
        data.agents.map((item, index) => {
          if (index === 0) {
            labels1.push({})
            labels1.push({})
            labels.push({ text: vm.$t('globalTrans.sl_no'), rowSpan: 2, alignment: 'center', style: 'th' })
            labels.push({ text: vm.$t('fertilizerConfig.godown_information.warehouse_name'), rowSpan: 2, alignment: 'center', style: 'th' })
            labels.push({ text: vm.$t('fertilizerReport.TransportAgentWiseFertilizerAmountAgainstProgramNo'), colSpan: totalLength, alignment: 'center', style: 'th' })
            var i
            for (i = 1; i < totalLength; i++) {
              labels.push({})
            }
            labels.push({ text: vm.$t('fertilizerReport.GrandTotalMTon'), rowSpan: 2, alignment: 'right', style: 'th' })
          }
          labels1.push(
            {
              text: (i18n.locale === 'bn') ? item.company_agent_name_bn : item.company_agent_name,
              style: 'th',
              alignment: 'right'
            }
          )
        })
        rowData.unshift(labels, labels1)
        const columnWids = ['10%', '15%']
        data.agents.map((item, index) => {
          columnWids.push('*')
        })
        columnWids.push('10%')
        pdfContent.push({
          table: {
            headerRows: 2,
            widths: columnWids,
            body: rowData
          }
        })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'landscape',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
            th: {
              fontSize: (i18n === 'bn') ? 12 : 10,
              margin: [3, 3, 3, 3]
            },
            td: {
              fontSize: (i18n === 'bn') ? 12 : 10,
              margin: [3, 3, 3, 3]
            },
            fertilizer: {
              margin: [0, 10, 0, 10]
            },
            header: {
              fontSize: 12,
              margin: [0, 0, 0, 4]
            },
            header2: {
              fontSize: 14,
              margin: [0, 10, 0, 20]
            },
            header3: {
              fontSize: 9,
              margin: [0, 0, 0, 0]
            },
            thLike: {
              fontSize: 10,
              margin: [3, 3, 3, 3]
            },
            agent: {
              fontSize: 9,
              margin: [50, 0, 0, 0]
            },
            address: {
              fontSize: 9,
              margin: [0, -10, 0, 0]
            },
            tableSubHead: {
              margin: [0, 5, 0, 15]
            },
            krishi: {
              margin: [0, -5, 0, 15],
              alignment: 'center'
            },
            report_type: {
              fontSize: 9,
              margin: [0, 2, 0, 15]
            }
          }
        }
        pdfMake.createPdf(docDefinition, null, null, null).download('carrirng-agent-wise-program-report')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfCarrying
}
